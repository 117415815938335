import { PUBLIC_ANALYTICS_TAGS } from '@livv/custom-algoliasearch/consts';
import { FC, ReactNode } from 'react';
import { ConfigureProps, InstantSearch as InstantSearchWrapper } from 'react-instantsearch';
import Configure from './algolia/Configure';
import { INDEX_NAME } from '@utils/consts/search';
import InsightsMiddleware, { searchClient } from '@utils/InsightsMiddlewareAlgolia';

interface InstantSearchProps {
    children: ReactNode;
    configureProps?: ConfigureProps;
    disableRouting?: boolean;
    indexName?: string;
}

const InstantSearch: FC<InstantSearchProps> = ({
    children,
    configureProps,
    disableRouting = false,
    indexName = INDEX_NAME,
}) => (
    <InstantSearchWrapper
        indexName={indexName}
        routing={!disableRouting}
        searchClient={searchClient}
    >
        <Configure analyticsTags={PUBLIC_ANALYTICS_TAGS} {...configureProps} />
        <InsightsMiddleware />
        {children}
    </InstantSearchWrapper>
);
export default InstantSearch;
