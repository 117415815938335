import algoliasearch from '@livv/custom-algoliasearch/lite';
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares';
import { useLayoutEffect } from 'react';
import { useInstantSearch } from 'react-instantsearch';
import aa from 'search-insights';
import { useAuth } from './context/auth';
import { getEnvVar } from '@config/configEnv';

const APP_ID = getEnvVar('ALGOLIA_APP_ID');
const SEARCH_API_KEY = getEnvVar('ALGOLIA_SEARCH_KEY');

const InsightsMiddleware = () => {
    const { addMiddlewares } = useInstantSearch();
    const { user } = useAuth();
    const uid = user?.uid;

    useLayoutEffect(() => {
        const middleware = createInsightsMiddleware({
            // TODO: remove this component when libraries update fixes the issue. Internally types have all necessary props
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            insightsClient: aa,
            onEvent: (event) => {
                const { insightsMethod, payload, widgetType } = event;
                // Send the event to Algolia
                // For now, we just send "click on result" event.
                if (widgetType === 'ais.hits' && insightsMethod === 'clickedObjectIDsAfterSearch') {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    aa(insightsMethod, payload);
                    // Send conversion event at the same time as click event to get enough data for the recommendation engine
                    // and avoid sending payload to document page through URL params (which is not looking good to end users)
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    aa('convertedObjectIDsAfterSearch', payload);
                }
            },
        });

        // Initialize analytics system https://www.npmjs.com/package/search-insights
        aa('init', {
            apiKey: SEARCH_API_KEY,
            appId: APP_ID,
        });

        if (uid) aa('setUserToken', uid);

        return addMiddlewares(middleware);
    }, [addMiddlewares, uid]);

    return null;
};

export const searchClient = algoliasearch(APP_ID, SEARCH_API_KEY);

export default InsightsMiddleware;
