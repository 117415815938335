import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import { ButtonLink, PlainLink } from '../Links';
import LanguageMenu from './LanguageMenu';
import Links from '@components/NavBar/DesktopNavigation/Links';
import MobileNavigation from '@components/NavBar/MobileNavigation/MobileNavigation';
import QuickSearch from '@components/NavBar/QuickSearch';
import UserMenu from '@components/NavBar/UserMenu';
import useUser from '@utils/context/user';
import getNavigationItems, { NavigationItem } from '@utils/navigation';

const navbarSx = {
    logoLink: {
        '&:focus-visible': {
            outline: '1px solid palette.grey[300]',
        },
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        mr: { md: 6, tablet: 1, xs: 1 },
    },
    nav: {
        '& > ul': {
            alignItems: 'baseline',
            listStyle: 'none',
            padding: 0,
        },
        ml: { tablet: 0.5, xs: 3 },
        mr: { tablet: 0 },
    },
    toolbar: {
        justifyContent: 'space-between',
        minHeight: { md: 70, xs: 54 },
    },
};

const HIDDEN_QUICK_SEARCH_PAGES = ['/', '/search'];

interface NavBarProps {
    clientOs?: string;
}

const renderNavigation = (items: NavigationItem[]) => {
    return (
        <Box component="nav" sx={navbarSx.nav}>
            <Links navigationItems={items} />
        </Box>
    );
};

const NavBar: FC<NavBarProps> = ({ clientOs }) => {
    const { asPath, route } = useRouter();
    const { t } = useTranslation('navBar');
    const theme = useTheme();
    const navigationItems = getNavigationItems();
    const { info: user, privileges } = useUser();

    const isHomePage = route === '/';

    const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
    // Check privileges to hide quick search on home page or search page if user can consume paid content
    // Show quick search on pages where RestrictedFallback component is shown

    const hideQuickSearch =
        HIDDEN_QUICK_SEARCH_PAGES.includes(route) && privileges?.isAllowedToConsumePaidContent;

    const redirectUrl = encodeURIComponent(asPath);

    return (
        <AppBar
            color={
                isHomePage && privileges?.isAllowedToConsumePaidContent
                    ? 'transparent'
                    : 'secondary'
            }
            data-test-id="appBar"
            position="static"
            enableColorOnDark
        >
            <Toolbar sx={navbarSx.toolbar}>
                <Box alignItems="center" display="flex">
                    <PlainLink href="/" sx={navbarSx.logoLink}>
                        <img
                            alt="Livv"
                            src={isTablet ? '/assets/logo_tablet.svg' : '/assets/logo_desktop.svg'}
                        />
                    </PlainLink>
                    {!hideQuickSearch && !isMobile && <QuickSearch clientOs={clientOs} />}
                </Box>
                {isTablet && !isMobile && renderNavigation(navigationItems)}
                <Box alignItems="center" display="flex">
                    {!isTablet && renderNavigation(navigationItems)}
                    {isMobile && <MobileNavigation />}
                    {user ? (
                        <UserMenu />
                    ) : (
                        <>
                            {!isLarge && (
                                <LanguageMenu
                                    sx={{ ml: { tablet: 1, xs: 0 }, mr: { tablet: 3, xs: 1 } }}
                                />
                            )}
                            <ButtonLink
                                href={`/signin?redirectUrl=${redirectUrl}`}
                                sx={{
                                    color: 'common.white',
                                    height: 40,
                                    mr: 2,
                                    px: 2,
                                }}
                                variant="text"
                            >
                                <p>{t('signin')}</p>
                            </ButtonLink>
                            <ButtonLink href="/signup" variant="contained">
                                {t('signup')}
                            </ButtonLink>
                        </>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
