import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useTranslation from 'next-translate/useTranslation';
import { FC, useState } from 'react';
import ButtonNestedMenu from '../ButtonNestedMenu';
import NavBarLink from '../NavBarLink';
import getNavigationItems, { NavigationLink } from '@utils/navigation';

const MobileNavigation: FC = () => {
    const { t } = useTranslation('navBar');
    const [anchorNav, setAnchorNav] = useState<null | HTMLElement>(null);
    const [isLegalToolsOpen, setIsLegalToolsOpen] = useState<boolean>(false);
    const navigationItems = getNavigationItems();

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorNav(null);
        setIsLegalToolsOpen(false);
    };

    const handleToggleLegalToolsLinks = () => {
        setIsLegalToolsOpen(!isLegalToolsOpen);
    };

    const renderMenuItem = (item: NavigationLink) => {
        const { isExternalLink, href, title } = item;

        return (
            <MenuItem key={title} sx={{ minHeight: '56px' }}>
                <NavBarLink
                    data-test-id={`${title}-nav-link`}
                    href={href}
                    onClick={handleCloseNavMenu}
                    sx={{ fontSize: 16, py: 1, width: '100%' }}
                    title={title}
                    {...(isExternalLink && {
                        target: '_blank',
                    })}
                >
                    {t(`link.${item.title}`)}
                </NavBarLink>
            </MenuItem>
        );
    };

    return (
        <>
            <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                aria-label={t('mainMenu')}
                color="inherit"
                data-test-id="mobileIcon"
                onClick={handleOpenNavMenu}
                size="large"
            >
                <Box display="flex" sx={{ alignItems: 'center', color: '#ffffff', mr: 2 }}>
                    {anchorNav ? <CloseIcon /> : <MenuIcon />}
                    <Typography sx={{ fontSize: 12, ml: 1 }}>{t('mobileNavigation')}</Typography>
                </Box>
            </IconButton>
            <Menu
                anchorEl={anchorNav}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                id="menu-appbar"
                onClose={handleCloseNavMenu}
                open={Boolean(anchorNav)}
                sx={{
                    '.MuiMenu-paper': { width: '100%' },
                    width: '100%',
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
                keepMounted
            >
                {navigationItems.map((navigationItem) => {
                    const { isMenu, title } = navigationItem;
                    // User link has no title
                    if (!title) {
                        return null;
                    }

                    if (isMenu === true) {
                        const { links } = navigationItem;

                        return (
                            <>
                                <MenuItem key={title} onClick={handleToggleLegalToolsLinks}>
                                    <ButtonNestedMenu
                                        isOpen={isLegalToolsOpen}
                                        links={links}
                                        menuTitle={title}
                                        onClick={handleToggleLegalToolsLinks}
                                    />
                                </MenuItem>
                                {isLegalToolsOpen && links.map((item) => renderMenuItem(item))}
                            </>
                        );
                    }

                    return renderMenuItem(navigationItem);
                })}
            </Menu>
        </>
    );
};

export default MobileNavigation;
