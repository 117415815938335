import { useRouter } from 'next/router';
import { FC, useState, useEffect, useCallback } from 'react';
import Button from './Button';
import useShortcuts from './hooks/useShortcuts';
import Modal from './Modal';
import { useFreemiumDialog } from '@utils/context/freemiumDialog';
import useUser from '@utils/context/user';
import { useAmplitude } from '@utils/hooks';
import { TrackingEventName } from '@utils/types/amplitude/events';

interface QuickSearchProps {
    clientOs?: string;
}

const QuickSearch: FC<QuickSearchProps> = ({ clientOs }) => {
    const [open, setOpen] = useState<boolean>(false);
    const router = useRouter();
    const { openFreemiumDialog } = useFreemiumDialog();
    const { events } = router;
    const { logEvent } = useAmplitude();
    const { info: user } = useUser();

    const handleOpen = useCallback(() => {
        logEvent({
            name: TrackingEventName.HEADER_QUICK_SEARCH_CLICKED,
            params: {
                logged_user: Boolean(user),
            },
        });
        if (openFreemiumDialog) {
            openFreemiumDialog();
        } else {
            setOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openFreemiumDialog]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    useShortcuts({ onClose: handleClose, onOpen: handleOpen });

    // Unfocus previous element after modal is closed.
    // By default focus is moved to the last focused element after modal is closed.
    useEffect(() => {
        if (!open) {
            (document.activeElement as HTMLElement).blur();
        }
    }, [open]);

    // handle close modal on route change (eg result clicked)
    useEffect(() => {
        const handleRouteChange = () => {
            setOpen(false);
        };

        events.on('routeChangeStart', handleRouteChange);

        return () => {
            events.off('routeChangeStart', handleRouteChange);
        };
    }, [events]);

    return (
        <>
            <Button clientOs={clientOs} onClick={handleOpen} />
            <Modal onClose={handleClose} open={open} />
        </>
    );
};

export default QuickSearch;
