import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface BaseNavigationItem {
    isExternalLink?: boolean;
    isFreeAccess?: boolean;
    isMenu: boolean;
    title?: string;
}

export interface NavigationLink extends BaseNavigationItem {
    Icon?: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>;
    // as is what is shown in the url - will be used with translation
    as?: string;
    // href is the internal url provided to the router
    href: string;
    isMenu: false;
}

export interface NavigationMenu extends BaseNavigationItem {
    isMenu: true;
    links: NavigationLink[];
}

export type NavigationItem = NavigationMenu | NavigationLink;

const getNavigationItems = (): NavigationItem[] => [
    { href: '/search', isExternalLink: false, isFreeAccess: false, isMenu: false, title: 'search' },
    {
        href: '/search/icicle',
        isExternalLink: false,
        isFreeAccess: false,
        isMenu: false,
        title: 'icicle',
    },
    {
        href: '/search/tree-view',
        isExternalLink: false,
        isFreeAccess: false,
        isMenu: false,
        title: 'treeView',
    },
    {
        isExternalLink: false,
        isMenu: true,
        links: [
            {
                href: '/glossary',
                isExternalLink: false,
                isFreeAccess: false,
                isMenu: false,
                title: 'glossary',
            },
            {
                href: '/books',
                isExternalLink: false,
                isFreeAccess: true,
                isMenu: false,
                title: 'books',
            },
            {
                href: '/legal-charts',
                isExternalLink: false,
                isFreeAccess: false,
                isMenu: false,
                title: 'legalCharts',
            },
            {
                href: 'https://livv.eu/articles',
                isExternalLink: true,
                isFreeAccess: true,
                isMenu: false,
                title: 'news',
            },
        ],
        title: 'legalTools',
    },

    { href: '/profile', isMenu: false },
];

export default getNavigationItems;
